<!-- 校务 -- 校务信息管理 -- 假期管理 -- 详情 -->
<template>
  <div>
    <div class="form">
      <el-form :model="form" ref="form" label-width="200rem"
               style="width: calc(100% - 300rem);margin-left:  22rem;margin-bottom: 100rem;min-height: calc(100vh - 412rem)">
        <el-form-item label="所属校区" prop="belong_school">
          <p>{{ form.belong_school_name }}</p>
        </el-form-item>
        <el-form-item label="假期名称" prop="vacation_name">
          <p>{{ form.vacation_name }}</p>
        </el-form-item>
        <el-form-item label="假期开始时间" prop="vacation_start_time">
          <p>{{ form.vacation_start_time }}</p>
        </el-form-item>
        <el-form-item label="假期结束时间" prop="vacation_end_time">
          <p>{{ form.vacation_end_time }}</p>
        </el-form-item>
        <el-form-item label="适用对象" prop="belong_user">
          <p>{{ form.belong_user_name }}</p>
        </el-form-item>
        <el-form-item label="适用范围" prop="belong_range">
          <p>{{ form.belong_range_name }}</p>
        </el-form-item>
      </el-form>
      <div class="footer-button">
        <el-button type="primary" @click="$router.back()">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
	_config:{"route":{"path":"details","meta":{"title":"详情"}}},
  data() {
    return {
      backType: 0,  //1为保存后返回，此时需要刷新list; 0为取消后返回，此时不需要刷新
      form: {},
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData(){
      this.$_axios2('/api/school-administration/vacation/details?id=' + this.$route.query.id ,{logic:1}).then(res => {
        this.form = res.data
      })
    },
  },
}
</script>

<style scoped>

.form {
  padding-top: 70rem;
}

::v-deep .el-form .el-input {
  min-width: 250rem;
}
</style>
